import {graphql, StaticQuery} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import React, {Component, createRef} from "react"
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaPodcast
} from "react-icons/fa"
import SEO from "../components/seo"
import "./signing-day-summit.sass"
import "./thank-you.sass"

class SigningDaySummit extends Component {
  constructor() {
    super()
    this.section1Ref = createRef()
  }

  scrollToSection1 = () => {
    this.section1Ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  render() {
    return (
      <>
        <SEO title="College Signing Day Virtual Summit" />
        <StaticQuery
          query={graphql`
            query {
              coverImg: file(relativePath: { eq: "img_4396.jpg" }) {
                sm: childImageSharp {
                  fluid(quality: 100, maxWidth: 576) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                md: childImageSharp {
                  fluid(quality: 100, maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                lg: childImageSharp {
                  fluid(quality: 100, maxWidth: 992) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
                xl: childImageSharp {
                  fluid(quality: 100, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          `}
          render={data => {
            const coverImgSource = [
              data.coverImg.sm.fluid,
              {
                ...data.coverImg.md.fluid,
                media: `(min-width: 768px)`
              },
              {
                ...data.coverImg.lg.fluid,
                media: `(min-width: 992px)`
              },
              {
                ...data.coverImg.xl.fluid,
                media: `(min-width: 1200px)`
              }
            ]
            return (
              <>
                <BackgroundImage
                  fluid={coverImgSource}
                  backgroundColor={`#9aa1a8`}
                  className="summit-hero"
                >
                  <div className="container summit-hero-content">
                    <h3>
                      <span>Save the date</span>
                      <span>
                        May 1<sup>st</sup> - 10:00 <small>A.M.</small>
                      </span>
                    </h3>
                  </div>
                </BackgroundImage>
                {/* ------------------------------------------------------- */}
                <div className="container section thank-you-section">
                  <h1>Congratulations</h1>
                  <p>
                    You've successfully registered for a valuable college
                    orientation program.
                  </p>
                  <p>
                    A link for the event will be sent to your email. You will
                    also receive an email reminder before the event.
                  </p>
                  <p>
                    If you do not receive an email before the event, be sure to
                    check your junk or spam for an email from the College
                    Success Professor or{" "}
                    <a href="mailto:successcoach@collegecareerstrategy.com">
                      successcoach@collegecareerstrategy.com
                    </a>
                    .
                  </p>
                  <p>
                    <strong>See you there!</strong>
                  </p>
                  <div className="social-container">
                    <a
                      href="https://www.instagram.com/collegesuccessprofessor/"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="social-icon social-icon-instagram"
                    >
                      <FaInstagramSquare />
                    </a>
                    <a
                      href="https://www.facebook.com/collegesuccessprofessor/"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="social-icon social-icon-facebook"
                    >
                      <FaFacebookSquare />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/frazierbadmus/"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="social-icon social-icon-linkedin"
                    >
                      <FaLinkedin />
                    </a>
                    <AniLink
                      to="/podcast"
                      className="social-icon social-icon-podcast"
                      swipe
                      duration={0.3}
                      entryOffset={100}
                      direction="left"
                    >
                      <FaPodcast />
                    </AniLink>
                  </div>
                </div>
                <div className="spacer-3" />
              </>
            )
          }}
        />
      </>
    )
  }
}
export default SigningDaySummit
